* {
  margin: 0;
  font-family: 'Quicksand', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body, #root {
  width: 100%;
  height: 100%;
}

.text-center {
  text-align: center;
}

.bold {
  font-weight: 600;
}

.fs20 {
  font-size: 20px;
}

.mb10 {
  margin-bottom: 10px !important;
}

.mb20 {
  margin-bottom: 20px !important;
}

.mb30 {
  margin-bottom: 30px !important;
}

.mb40 {
  margin-bottom: 40px !important;
}

.DialogTitlePR {
  padding-right: 50px !important;
}

.MuiFormLabel-root {
  font-weight: 500 !important;
}

.Former {
  .MuiFormHelperText-root {
    margin-left: 0;
  }
}

td.MuiTableCell-root {
  font-size: 16px;
}

.FormLabelContainer {
  .FormLabel {
    height: 34px;
    display: flex;
    align-items: center;

    svg {
      margin-left: 5px;
      cursor: pointer;
    }
  }

  .MuiAlert-standard {
    padding: 7px !important;
  }

  .MuiAlert-icon, .MuiAlert-message {
    padding: 0 !important;
  }
}

@import 'react-big-calendar/lib/css/react-big-calendar.css';
